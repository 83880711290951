import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import SVG from "./svg"
import Button from "./button"

const InfoBox = styled.div`
  margin: 0 ${(p) => (p.align === "right" ? "70px 0 0" : "0 0 70px")};
  max-width: ${(p) => (p.bg ? "400px" : "450px")};
  width: inherit;
  min-width: 270px;
  padding: ${(p) => (p.bg ? "50px 0 50px 60px" : "auto")};

  @media (max-width: 768px) {
    margin: 0px;
    padding: 0px;
    width: auto;
    padding: ${(p) => (p.bg ? "20px 10px 60px 10px" : "auto")};
  }

  h2 {
    color: ${(props) => props.theme.DarkBlue};
    margin: 10px 0;
    font-size: 3.2em;
    line-height: 30px;
    font-family: ${(p) => (p.bg ? "Montserrat" : "interstate-compressed")};
    font-weight: 800;
  }

  h3 {
    color: ${(props) => props.theme.Blue};
    margin: 10px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 1.2px;
  }

  p {
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 10px 0 20px 0;
    color: ${(props) => props.theme.DarkBlue};
  }
`

const ImageStyled = styled.div`
  position: relative;
  width: inherit;
  max-width: 450px;
  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
  .img {
    transition: width 600ms;
    width: inherit;
    overflow: hidden;
    max-height: 450px;
    width: 450px;

    @media (max-width: 1450px) {
      max-width: 450px;
      width: 400px;
    }

    @media (max-width: 768px) {
      width: 94vw;
      margin: 0 5px;
    }
  }
`

const FeatureStyled = styled.div`
  max-width: 980px;
  margin: ${(p) => p.margin || "0px auto"};
  z-index: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0px 4px;
  flex-direction: ${(p) => (p.align === "left" ? "row-reverse" : "row")};

  .swave {
    position: absolute;
    z-index: 2;
    top: 100px;
    right: ${(p) => (p.align === "left" ? "auto" : "-80px")};
    left: ${(p) => (p.align === "left" ? "-80px" : "auto")};
    margin: 20px 0 0 0;
    transform: scaleX(${(p) => (p.align === "left" ? "-1" : "1")});
    display: ${(p) => (p.no_wave ? "none" : "block")};

    @media (max-width: 768px) {
      display: ${(p) => (p.no_wave ? "none" : "block")};
    }
  }

  .stuck {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin: auto;
  }

  .blueBG {
    background: ${(p) => p.theme.BGBlue};
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 5%;
    left: 0%;
    right: 10%;

    @media (max-width: 768px) {
      top: 15%;
      bottom: 0%;
      left: 0;
      right: 0;
    }
  }

  h2 {
    text-transform: uppercase;
    margin: 0;
    font-size: ${(p) => (p.bg ? "2.4em" : "7.2em")};
    line-height: ${(p) => (p.bg ? "30px" : ".9em")};
    letter-spacing: 1.2px;
  }

  h3 {
    text-transform: uppercase;
  }

  .featureCTA {
    font-size: 2em;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};

    @media (min-width: 768px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 50px 0;
  }
`

const Feature = ({
  bg,
  align,
  title,
  headline,
  margin,
  sub_heading,
  summary,
  image2,
  image,
  button_label,
  wave_color,
  button_link,
  eagerLoadImage,
  cta_link,
  cta_label,
  no_wave,
}) => {
  let img
  const t = title || headline
  const im = image || image2
  if (im && im.localFile)
    img = (
      <Img
        fluid={im.localFile.childImageSharp.fluid}
        alt={t}
        className="img"
        loading={eagerLoadImage ? "eager" : "lazy"}
      />
    )
  if (im && typeof im === "string") img = <img className="img" src={image2} />

  return (
    <FeatureStyled align={align} bg={bg} margin={margin} no_wave={no_wave}>
      {bg ? <div className="blueBG" /> : ""}
      <InfoBox align={align} bg={bg}>
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: t,
          }}
        />
        {sub_heading ? (
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: sub_heading,
            }}
          />
        ) : (
          ""
        )}
        {bg ? (
          <SVG name="SWave" className="swave stuck" color={wave_color} />
        ) : (
          ""
        )}
        <p
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: summary,
          }}
        />{" "}
        {cta_link && cta_label ? (
          <Link className="featureCTA" to={cta_link}>
            {cta_label}
          </Link>
        ) : (
          ""
        )}
        {button_label ? <Button to={button_link}>{button_label}</Button> : ""}
      </InfoBox>
      <ImageStyled>
        {bg ? "" : <SVG name="SWave" className="swave" color={wave_color} />}
        {img}
      </ImageStyled>
    </FeatureStyled>
  )
}

export default Feature
