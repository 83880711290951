import React, { useContext } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../components/layout"
import Button from "../components/button"
import Inner from "../components/inner"
import RoughEdge from "../components/rough-edge"
import Center from "../components/center"
import Rotator from "../components/rotator"
import Garnish from "../components/garnish"
import PageTopContentStyled from "../components/page-top-content"
import SEO from "../components/seo"
import SVG from "../components/svg"
import CTA from "../components/cta"
import ResponsiveSpacing from "../components/responsive-spacing"
import Img from "gatsby-image/withIEPolyfill"
import Feature from "../components/feature"
import Carousel from "../components/carousel"
import Heading from "../components/heading"
import HeroArea from "../components/hero"

const CertificationStyled = styled.div`
  width: 100%;
  background: ${(p) => p.theme.DarkBlue};
  position: relative;

  > .inner {
    display: flex;
    padding: 45px 0;
    align-items: center;
    justify-center: center;
    flex-flow: column;
  }

  .certs {
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 730px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100vw;
      min-width
    }
  }

  .certs > .svg {
    height: 53px;
    display: inline-block;
    @media (max-width: 768px) {
      min-width: 30%;
      margin: 10px 0;
    }
  }

  .read-more {
    color: ${(p) => p.theme.White};
    text-align: center;
    text-decoration: none;
  }

  .cert img {
    height: 53px;
  }

  h2 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.White};
    text-align: center;
    font-size: 6em;
    margin: 0 0 8px 0;
    letter-spacing: 2px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 4em;
    }
    .certs {
      justify-content: center;
    }
  }
`

const Certifications = ({ fields }) => {
  const theme = useContext(ThemeContext)
  const g = fields.margin_images
  return (
    <CertificationStyled>
      <Garnish
        garnish={g[3]}
        pSpeed={-0.25}
        top={-550}
        rotation={-35}
        rSpeed={0.1}
        pos={"left"}
      />
      <Garnish
        garnish={g[2]}
        pSpeed={-0.2}
        top={-700}
        rotation={0}
        rSpeed={-0.1}
        pos={"right"}
      />
      <RoughEdge className="top" color={theme.DarkBlue} />
      <Inner
        className="inner"
        maxWidth="1000px"
        spacing="100px 0 0 0"
        mobileSpacing="100px 0 0 0"
      >
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.certifications.headline,
          }}
        />
        <div className="certs">
          {fields.certifications.icons &&
            fields.certifications.icons.map((c, i) => (
              <SVG className="svg" name={c} key={i} color="white" />
            ))}
        </div>
        <Link className="read-more" to="/ingredients/#certifications">
          <CTA color="white">{fields.certifications.link_text}</CTA>
        </Link>
      </Inner>
      <RoughEdge className="bottom" color={theme.DarkBlue} />
    </CertificationStyled>
  )
}

const PointsStyled = styled.div`
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  @media (max-width: 768px) {
    padding: 10px;
  }

  .point {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 2.4em;
    justify-content: center;
    width: 32%;
    height: 260px;
    color: ${(p) => p.theme.DarkBlue};
    font-family: "interstate-compressed";

    img {
      max-height: 150px;
      max-width: 320px;
    }
  }
  a {
    color: ${(p) => p.theme.DarkBlue};
  }
  @media (max-width: 768px) {
    .point {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }
    .point img {
      max-width: 100%;
      min-height: auto;
    }
  }
`

const Points = ({ fields }) => {
  return (
    <PointsStyled>
      {fields.points.length > 0
        ? fields.points.map((p, i) => {
            return (
              <Link key={i} to={p.link} className="point">
                <img src={p.image.source_url} alt={p.label} />
                <div className="move-up">
                  <CTA>{p.label}</CTA>
                </div>
              </Link>
            )
          })
        : ""}
    </PointsStyled>
  )
}

const Content = ({ fields }) => {
  const theme = useContext(ThemeContext)
  return (
    <PageTopContentStyled>
      <RoughEdge className="top" color={theme.White} />
      <Inner
        className="inner"
        maxWidth="1000px"
        spacing="100px 0 0 0"
        mobileSpacing="100px 0 0 0"
      >
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.top_points.headline,
          }}
        />
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.top_points.sub_heading,
          }}
        />
        <Points fields={fields.top_points} />
      </Inner>
    </PageTopContentStyled>
  )
}

const FeaturesStyled = styled.div`
  position: relative;

  .orgCert {
    position: absolute;
    top: -150px;
    width: 148px;
    left: 50%;
    margin-left: -550px;
    transition: left 600ms, margin-left 600ms;
    transform: rotate(-25deg);

    @media (max-width: 1112px) {
      left: 10px;
      margin-left: 0px;
    }
  }
`

const CarouselCTA = styled.div`
  position: absolute;
  height: 60px;
  max-width: 200px;

  .img,
  .img img {
    height: inherit;
    object-fit: scale-down !important;
    max-width: inherit !important;
  }
  margin: -25px 0 0 40px;

  @media (max-width: 768px) {
    margin: -33px 0 0 20px;
    height: 60px;
  }
`

const Features = ({ children }) => {
  return <FeaturesStyled>{children}</FeaturesStyled>
}

const IndexPage = () => {
  const data = useStaticQuery(homeQuery)
  const fields = data.home.edges[0].node.acf
  const features = fields.features
  const g = fields.margin_images

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  const carouselCTASource = fields.carousel.mobile_instructions.localFile
    .childImageSharp.fixed
    ? [
        {
          media: "(min-width: 769px)",
          ...fields.carousel.instructions.localFile.childImageSharp.fixed,
        },
        {
          media: "(max-width: 768px)",
          ...fields.carousel.mobile_instructions.localFile.childImageSharp
            .fixed,
        },
      ]
    : fields.carousel.instructions
    ? [
        {
          ...fields.carousel.instructions.localFile.childImageSharp.fixed,
        },
      ]
    : []

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <HeroArea fields={fields}>
        <Button to={fields.hero_area.button_link}>
          {fields.hero_area.button_label}
        </Button>
      </HeroArea>
      <ResponsiveSpacing>
        <Content fields={fields} />
        <Rotator
          pSpeed={0.2}
          top={0}
          rotation={0}
          rSpeed={0}
          style={{
            position: "absolute",
            zIndex: "-1",
            height: "700px",
            right: "0",
            top: "1550px",
            width: "1400px",
          }}
        >
          <Img
            fluid={data.bgfile.childImageSharp.fluid}
            aria-hidden="true"
            role="decorative"
            alt=""
          />
        </Rotator>
        <Features>
          <SVG
            name="OrganicCert"
            className="orgCert"
            color={"rgb(183, 211, 219)"}
          />
          <Feature {...features[0]} align={"right"} margin="150px auto" />
          <Feature {...features[1]} align={"left"} margin="150px auto" />
          <Garnish
            garnish={g[0]}
            pSpeed={-0.3}
            top={-10}
            width={150}
            rotation={0}
            rSpeed={-0.1}
            pos={"left"}
          />
          <Garnish
            garnish={g[1]}
            pSpeed={-0.2}
            top={0}
            rotation={0}
            width={200}
            rSpeed={0}
            pos="right"
            style={{
              top: "100px",
            }}
          />
        </Features>
      </ResponsiveSpacing>
      <Heading margin="50px 0" mobileMargin="40px 0">
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.carousel.headline,
          }}
        />
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.carousel.sub_heading,
          }}
        />
      </Heading>
      <CarouselCTA>
        <Img className="img" fixed={carouselCTASource} />
      </CarouselCTA>
      <Carousel />
      <Center margin="0 auto 150px auto" mobileMargin="0 auto 50px auto">
        <Button to={"/flavors"}>{fields.carousel.button_label}</Button>
      </Center>
      <Certifications fields={fields} />
      <ResponsiveSpacing>
        <Feature {...features[2]} align={"right"} margin="150px auto" />
      </ResponsiveSpacing>
    </Layout>
  )
}

const homeQuery = graphql`
  query {
    bgfile: file(relativePath: { eq: "light-blue-brushstroke.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home: allWordpressPage(filter: { slug: { eq: "home-page" } }) {
      edges {
        node {
          acf {
            hero_area {
              top_line
              headline
              top_line_color
              headline_color
              wave_color
              button_label
              button_link
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              mobile_background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 768, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            margin_images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 178, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            features {
              headline
              sub_heading
              summary
              wave_color
              button_label
              button_link
              image2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            top_points {
              headline
              sub_heading
              points {
                link
                label
                image {
                  source_url
                }
              }
            }
            certifications {
              headline
              icons
              link_text
            }
            carousel {
              headline
              sub_heading
              button_label
              instructions {
                localFile {
                  childImageSharp {
                    fixed(height: 60, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              mobile_instructions {
                localFile {
                  childImageSharp {
                    fixed(height: 60, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
