import React, { useEffect, useState } from "react"
import styled from "styled-components"
import throttle from "../utils/throttle"

const RotatorStyled = styled.div`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex || 3};
  transition: transform 0ms;
  @media (max-width: 768px) {
    display: none;
  }
  > .img {
    height: ${(p) => (p.width || 150) + "px"} !important;
    width: ${(p) => (p.width || 150) + "px"} !important;
    > img {
      width: ${(p) => (p.width || 150) + "px"} !important;
      height: ${(p) => (p.width || 150) + "px"} !important;
    }
  }
`

const Rotator = ({
  top = 0,
  rotation = 0,
  pSpeed = 0,
  rSpeed = 0,
  children,
  style,
  width,
}) => {
  const [scrolled, setScrolled] = useState(0)
  let pageY = 0

  const handleScroll = () => {
    setScrolled(window.pageYOffset || document.documentElement.scrollTop)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(), false)
    window.addEventListener("resize", () => handleScroll(), false)
    return () => {
      window.removeEventListener("scroll", () => handleScroll())
      window.removeEventListener("resize", () => handleScroll())
    }
  }, [])

  const rMove = scrolled * (rSpeed || 0.5).toFixed(2)
  const pMove = scrolled * (pSpeed || 0.5).toFixed(2)

  const rotate = rSpeed ? `rotate(${rotation - rMove}deg)` : ""
  const translate = pSpeed ? `translate3d(0, ${top - pMove + "px"}, 0)` : ""
  const transform = `${translate} ${rotate}`

  return (
    <RotatorStyled
      style={{
        transform,
        width: width || "200px",
        ...style,
      }}
    >
      {children}
    </RotatorStyled>
  )
}

export default Rotator
