import React from "react"
import styled from "styled-components"
import Rotator from "./rotator"
import Img from "gatsby-image/withIEPolyfill"

const SPEED_MULTIPLIER = 1
const ROTATION_MULTIPLIER = 1

const GarnishStyled = styled.div`
  position: absolute;
  top: 0;
  width: ${p => (p.width || 150) + "px"};
  height: ${p => (p.width || 150) + "px"};

  &.gLeft {
    left: 50%;
    margin-left: -800px;
  }
  &.gRight {
    right: 50%;
    margin-right: -780px;
  }
`

const Garnish = ({
  garnish,
  pSpeed,
  top,
  rotation,
  rSpeed,
  style,
  pos,
  width,
}) => {
  if (typeof garnish === "undefined") return ""
  const img = garnish.image ? garnish.image : garnish
  const posClass = pos === "left" ? "gLeft" : "gRight"
  return (
    <GarnishStyled className={posClass} width={width}>
      <Rotator
        pSpeed={pSpeed * SPEED_MULTIPLIER}
        top={top}
        width={width}
        rotation={rotation}
        rSpeed={rSpeed * ROTATION_MULTIPLIER}
        style={{ width: width + "px", ...style }}
      >
        {img.localFile.childImageSharp.fixed ? (
          <Img className="img" fixed={img.localFile.childImageSharp.fixed} />
        ) : (
          <Img className="img" fluid={img.localFile.childImageSharp.fluid} />
        )}
      </Rotator>
    </GarnishStyled>
  )
}

export default Garnish
