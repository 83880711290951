import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const BreadCrumbsStyled = styled.div`
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-wrap: no-wrap;
  min-width: 100%;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: "Montserrat";

  @media (max-width: 768px) {
    display: none;
  }

  > div {
    align-items: center;
    display: flex;
    flex-wrap: no-wrap;
  }

  i.arrow {
    font-style: normal;
    margin: 0 6px;
    font-size: 26px;
    color: ${p => p.color || p.theme.DarkBlue};
    height: 26px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 1068px) {
      /* font-size: 20px; */
    }
  }
  a,
  span {
    color: ${p => p.color || p.theme.DarkBlue};
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 12px;
    text-decoration: none;
    font-weight: bold;

    @media (max-width: 1068px) {
      /* font-size: 8px; */
    }
  }
  a {
    text-decoration: underline;
  }
  i.arrow.hidden {
    opacity: 0;
  }
  @media (max-width: 768px) {
    /* font-size: 0.5em; */
  }
`

const BreadCrumbs = ({ trail, className, color }) => {
  return (
    <BreadCrumbsStyled className={className} color={color}>
      {trail.map((t, i) => {
        const hidden = i === trail.length - 1 ? "hidden" : ""
        if (t.link) {
          return (
            <div key={i}>
              <Link to={t.link}>{t.label}</Link>
              <i className={`arrow ${hidden}`}>&rsaquo;</i>
            </div>
          )
        } else {
          return (
            <div key={i}>
              <span>{t.label}</span>
              <i className={`arrow ${hidden}`}>&rsaquo;</i>
            </div>
          )
        }
      })}
    </BreadCrumbsStyled>
  )
}

export default BreadCrumbs
