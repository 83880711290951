import styled from "styled-components"

const PageTopContentStyled = styled.div`
  margin: 0px auto;
  width: 100%;
  text-align: center;
  padding: 0px 0;
  position: relative;
  z-index: 1;

  > .inner {
    min-height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  h2 {
    margin: 0;
    font-size: 6em;
    line-height: 1em;
    color: ${(p) => p.theme.DarkBlue};
    font-family: "interstate-compressed";
    font-weight: 800;
    letter-spacing: 2px;
  }

  h3 {
    margin: 0;
    font-size: 2.4em;
    color: ${(p) => p.theme.Blue};
    font-family: "Montserrat";
  }

  @media (max-width: 678px) {
    padding: 0 10px;

    > .inner {
      padding: 50px 0;
    }
    h2,
    h3 {
      padding: 0 10px;
    }
  }
`

export default PageTopContentStyled
